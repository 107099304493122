import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

function App() {
  const [items, setItems] = useState([]);
  const [itemsPig, setItemsPig] = useState([]);
  const [itemsChicken, setItemsChicken] = useState([]);

  const [hideFullscreen, setHideFullscreen] = useState("");
  const translateFullBClass = "-translate-y-[100%]";
  const translateFullTClass = "";

  const [moveDiv, setMoveDiv] = useState(translateFullTClass);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  const [width, setWidth] = useState(window.innerWidth);

  function delay(time) {
    return new Promise((r) => setTimeout(r, time));
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const doAnimation = () => {
    delay(5000).then(() => {
      setMoveDiv(translateFullBClass);
      delay(60000).then(() => {
        setMoveDiv(translateFullTClass);
        delay(60000).then(() => {
          setShouldAnimate(!shouldAnimate);
        });
      });
    });
  };

  const enterFullscreen = () => {
    let root = document.getElementById("root");
    root.requestFullscreen();
    setHideFullscreen("hidden");
  };

  useEffect(() => {
    //console.log(width);
    if (width > 640) {
      doAnimation();
    }
  }, []);
  useEffect(() => {
    if (width > 640) {
      doAnimation();
    }
  }, [shouldAnimate]);

  useEffect(() => {
    axios
      .get("https://dccarnes.com/dccarnesAPI/v1.0/getProducts/")
      .then((response) => {
        //console.log(response.data);
        setItems(response.data.beef);
        setItemsPig(response.data.pig);
        setItemsChicken(response.data.chicken);
      });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div
      className="App h-screen w-screen bg-gray-700 p-12 md:overflow-hidden overflow-scroll"
      style={{
        backgroundImage: `url("/img/bg.webp")`,
      }}
    >
      <div
        className={`${`transition-transform duration-[60000ms] ease-linear ${moveDiv}`}`}
      >
        <div className={`grid grid-cols-1 gap-4 sm:grid-cols-2 grid-rows-2`}>
          <div className="bg-white  row-span-2 flex">
            <img className="my-auto" src="/img/logo.jpeg" alt="" />
          </div>
          {items.map((item) => (
            <div
              key={item.id}
              className="row-span-1 opacity-90 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-500"
            >
              <div className="flex-shrink-0">
                {item.tipo === "RES" && (
                  <img className="h-10 w-10" src="/img/bull.png" alt="" />
                )}
              </div>
              <div className="flex-1 min-w-0">
                <a href="#" className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-4xl font-medium text-gray-900">
                    {item.nombre}
                  </p>
                  <p className="text-3xl font-bold text-orange-600 truncate">
                    ${item.precio}
                  </p>
                </a>
              </div>
            </div>
          ))}
        </div>
        <div
          className={`grid grid-cols-1 gap-4 sm:grid-cols-2 grid-rows-2 mt-6`}
        >
          <div className="bg-white  row-span-2 flex">
            <img className="my-auto" src="/img/logo.jpeg" alt="" />
          </div>
          {itemsPig.map((item) => (
            <div
              key={item.id}
              className="row-span-1 opacity-90 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-500"
            >
              <div className="flex-shrink-0">
                {item.tipo === "CERDO" && (
                  <img className="h-10 w-10" src="/img/pig.png" alt="" />
                )}
              </div>
              <div className="flex-1 min-w-0">
                <a href="#" className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-4xl font-medium text-gray-900">
                    {item.nombre}
                  </p>
                  <p className="text-3xl font-bold text-orange-600 truncate">
                    ${item.precio}
                  </p>
                </a>
              </div>
            </div>
          ))}
        </div>
        <div
          className={`grid grid-cols-1 gap-4 sm:grid-cols-2 grid-rows-2 mt-6`}
        >
          <div className="bg-white  row-span-2 flex">
            <img className="my-auto" src="/img/logo.jpeg" alt="" />
          </div>
          {itemsChicken.map((item) => (
            <div
              key={item.id}
              className="row-span-1 opacity-90 relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-500"
            >
              <div className="flex-shrink-0">
                {item.tipo === "POLLO" && (
                  <img className="h-10 w-10" src="/img/chicken.png" alt="" />
                )}
              </div>
              <div className="flex-1 min-w-0">
                <a href="#" className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-4xl font-medium text-gray-900">
                    {item.nombre}
                  </p>
                  <p className="text-3xl font-bold text-orange-600 truncate">
                    ${item.precio}
                  </p>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`h-12 w-12 bg-red-500 rounded border-black absolute top-2 right-2 ${hideFullscreen}`}
        onClick={enterFullscreen}
      ></div>
    </div>
  );
}

export default App;
